const tpl =
// `<p>致：<u>{{toCompanyName}}</u></p>
// <p style="text-indent:30px">我司（公司名称：<u>{{fromCompanyName}}</u>,&nbsp;&nbsp;统一社会信用代码：<u>{{fromCompanyCode}}</u>），通过宜宾市公共资源交易中心数字证书登陆，就本次招标项目向贵司申请出具电子投标保函，保证金额为<u>{{securityAmount}}</u>元（大写<u>{{securityAmountInWords}}</u>），现作出如下承诺和要约:</p>
// <p>一、我司声明及保证如下：</p>
// <p>1.1我司是根据中国法律依法注册登记并合法存续的具有法人地位的企业法人，依法独立开展经营活动，并以全部资产独立对外承担民事责任。</p>
// <p>1.2我司坚持公开、公平、公正和诚信原则参加招标项目投标，严格遵守招标单位招投标项目的工作流程以及招标文件中对投标人的投标要求，保证招标投标项目依法规范运行。</p>
// <p>1.3我司申请贵司提供担保符合我司章程规定；获得相应批准权限的机关批准；取得了出具、签署双方合作中所需法律文件的相应授权。</p>
// <p>1.4严格按照贵司的要求提供有关资料与信息，并保证本申请邀约函所陈述的各项内容以及所提供的资料与信息真实、完整、合法、有效。</p>
// <p>1.5我司要约得到贵司承诺后，如我司发生下述可能影响双方合作的重大事项，在事项发生之日起3日内书面向贵司通报：</p>
// <p>1.5.1发生承包、托管（接管）、租赁、股份制改造、减少注册资本金、投资、联营、合并、兼并、收购重组、分立、合资、（被）申请停业整顿、申请解散、被撤销、（被）申请破产、控股股东/实际控制人变更或重大资产转让、停产、歇业、被有权机关施以高额罚款、被注销登记、被吊销营业执照、涉及重大法律纠纷、生产经营出现严重困难或财务状况恶化、或者法定代表人或主要负责人无法正常履行职责，可能影响被保证人偿债/履约能力；</p>
// <p>1.5.2因国家征收、没收、征用、无偿收回或其他不合理原因导致财产非正常毁损、灭失、价值减少；</p>
// <p>1.5.3发生或可能发生超过注册资本20％的重大资产转让、处置、举借新债、为贵司以外的任何第三人提供担保、反担保等；</p>
// <p>1.5.4财产被查封、扣押、冻结、扣划、留置、拍卖、行政机关监管，或者权属发生争议，已发生或可能发生的与我司有关的诉讼（包括仲裁、被申请强制执行）、资产被查封、冻结、扣押或被采取其它强制措施等情形；</p>
// <p>1.5.5出现丧失或可能丧失履行债务能力的其它情形；</p>
// <p>1.5.6其他可能影响双方合作的事项。</p>

// <p>二、我司保证向贵司交纳担保费及手续费</p>
// <p><span style="color:#ff2600">2.1我司承诺在收到贵司同意出具保函通知之日起<u>&nbsp;&nbsp;壹&nbsp;&nbsp;</u>日内自愿足额向贵司缴纳担保费和手续费共计<u>{{insuranceAmount}}</u>元。大写<u>{{insuranceAmountInWords}}</u></span></p>
// <p>2.2如我司未按期足额缴纳上述费用，贵司无需再出具投标保函，且无需承担任何责任。</p>
// <p>2.3我司确认，上述费用为含税价。</p>
// <p>2.4电子投标系统确定开标后，贵司在收到我司开具电子发票的书面通知后，根据我司的要求开具电子发票。</p>

// <p>三、我司的违约责任</p>
// <p>3.1贵司按照《投标保函》向受益人履行了保证责任后，我司自愿放弃一切抗辩权利，并于贵司承担保证责任之日起<u>&nbsp;&nbsp;壹&nbsp;&nbsp;</u>日内向贵司清偿所有款项，包括但不限于支付的全部款项和利息（利息按每日万分之五计算）、《投标保函》或其他承担保证责任的文件无效而导致贵司支出的赔偿费、贵司为代偿追偿产生的律师费、调查取证费、诉讼费、保全费、评估费、拍卖费、公证费、差旅费、实现保证金质权的费用等。</p>
// <p>3.2我司未履行或未完全履行本邀约函所承诺、保证的任一义务的，我司自愿向贵司承担赔偿损失的法律责任，并自愿支付惩罚性违约金，惩罚性违约金以保证金额的<u>&nbsp;&nbsp;20%&nbsp;&nbsp;</u>计算。</p>
// <p>3.3在我司履行完毕本邀约函项下全部义务和责任之前，贵司有权随时查询我司的信用信息和授信情况、工商内档等信息，我司应积极配合并按贵司要求出具同意查询的授权文书。</p>
// <p>3.4若我司违反承诺、保证义务的（包括虚构、隐瞒信息资料等），贵司除向我司主张违约责任外，有权在贵司官网、中国担保网或其他媒体上公布我司违约情况，由此产生的后果由我司自行负责。</p>

// <p>四、贵司承诺的方式</p>
// <p>4.1贵司并无必须提供担保的义务，贵司有权根据我司申请业务情况自行确定担保期限等保函内容。</p>
// <p>4.2我司点击下方“我已阅读并同意《投标保函申请邀约函》”即表明我司向贵司出具本邀约函。我司向贵司出具本邀约函，并向贵司交纳相关费用，经贵司审核后，由贵司出具保函的法律文件，即视为贵司接受我司本邀约函项下要约并就我司要约进行了承诺。自贵司承诺之日起，我司与贵司的合同关系成立，我司将受本函所载条款和条件之约束，承担全部义务与责任。</p>

// <p>五、邀约函的生效及撤销</p>
// <p>5.1本邀约函自我司勾选“我已阅读并同意《投标保函申请邀约函》”并点击确认之时起生效。</p>
// <p>5.2除经贵司书面同意，否则本邀约函一经生效，不得撤销、撤回或变更。</p>

// <p>六、退费条件当有且仅有以下情形发生时我司可在线申请退回担保费和手续费： </p>
// <p>1.开标前我司合法放弃投标的；</p>
// <p>2.开标前招标项目发生流标、终止的；</p>
// <p>3.开标后评标前项目发生流标的；</p>
// <p>4.我司办理退保事宜，满足前述退保条件的，贵司应全额退还担保费及手续费，已经产生的税费由我司自行承担。</p>
// <p>七、我司同贵司就本邀约函及贵司基于本邀约函做出的承诺在履行过程中发生的争议，可向贵司所在地人民法院提起诉讼。 </p>
// <p>&nbsp;&nbsp;&nbsp;&nbsp;我司以上对贵司所做的所有陈述及向贵司提供的所有文件和材料，均真实、合法、有效、完整、准确。我司声明，上述要约事项如有虚假、隐瞒即视为违约，我司愿意承担相应的法律责任。</p>
// <p></p>
// <p>（以下无正文）</p>`;

// `<p>一、名称：投标电子保函。</p>
// <p>二、保函形式：本次投保保函形式为电子保函。根据《中华人民共和国民法典》第四百六十九条规定，数据电文是合法的合同表现形式，电子保函与纸质保函具有同等法律效力。</p>
// <p>三、请您在投保前务必详细阅读泰州市公共资源交易中心《泰州市建设工程投标保证保险承保、退保、理赔规则标准》等内容。</p>
// <p>四、投标保函业务担保费率如下</p>
// <p>投标保函按照开函金额收取0.3%的担保费，最低收费500元/笔。</p>
// <p>五、请您在投保前务必详细阅读以下文件，并认真对待：</p>
// <p>（一）《泰州市建设工程投标保证保险投保须知》，附件下载。</p>
// <p>（二）《泰州市建设工程投标保证保险投保人（投标人）操作手册》，附件下载。</p>
// <p>（三）《泰州市建设工程投标保证保险承保、退保、理赔规则标准》，附件下载。</p>
// <p>若开函金融机构选择苏州国发融资担保，请参考《苏州国发融资担保有限公司电子保函操作手册》进行操作，附件下载。</p>
// <p>六、电子投标保函由金融机构提供，电子保函与纸质保函具有同等法律效力。</p>
// <p>七、请您于投保项目《招标文件》规定的“投标截止时间”前完成保费支付，否则投保无效。</p>
// <p>八、发票申请操作详见《泰州市建设工程投标保证保险投保人（投标人）操作手册》。</p>
// <p>九、退保申请操作详见《泰州市建设工程投标保证保险承保、退保、理赔规则标准》 和《泰州市建设工程投标保证保险投保人（投标人）操作手册》。</p>
// <p>我已知晓通过本系统向保证人申请的电子保函过程中所有阅读、点击的内容均可作为法律证据，如保证人做出代偿可凭此内容作为法律证据向我司追偿本金及保函中约定的其它费用。</p>`;


`
<p>阅读保函开立须知</p>
<p>一、名称：投标保函。</p>
<p>二、保函形式：电子保函。根据《中华人民共和国民法典》第四百六十九条规定，电子保函与纸质保函具有同等法律效力。</p>
<p>三、请您在投保前务必详细阅读《投标保函申请书》，《投标保函开立、退费、代偿规则》等内容。</p>
<p>四、保函开立人根据相关费率标准综合确定，按照保函最高担保金额的0.5%收取担保费，低于500元的按500元收取。</p>
<p>五、请您在投保前务必详细阅读以下文件，并认真对待：</p>
<p>（一）《电子保函服务平台操作手册》，<a href="/api/insure/index/download?type=manual" target="_blank">附件下载</a>。</p>
<p>（二）《投标保函开立、退费、代偿规则》，<a href="/api/contract/download?source=202206/a1e9a0fb21ea46d5839d8fec1ec6ca2e.docx" target="_blank">附件下载</a>。</p>
<p>六、投标保函由龙之宝担保公司提供。</p>
<p>七、请您于项目《招标文件》规定的“投标保证金缴纳截止时间”前完成担保费支付，否则投保无效。</p>
<p>八、发票申请操作详见《电子保函服务平台操作手册》。</p>
<p>九、退保申请操作详见《投标保函开立、退费、代偿规则》和《电子保函服务平台操作手册》。</p>
<p>我已知晓通过本系统向保函开立人申请的投标保函过程中所有阅读、点击的内容均可作为法律证据，如保函开立人做出代偿可凭此内容作为法律证据向我司追偿本金及投标保函申请书中约定的其它费用。</p>

var vm=new Vue({ el:'#app', data:{ msg:'
{{insureNotice}}
' } })
`;


// 宣城市中小企业融资担保有限公司
// 投标电子保函业务介绍

// 注意事项
// 1．保函开立人介绍
//     宣城市中小企业融资担保有限公司，是2002年8月经宣城市人民政府批准设立的国有企业，目前公司注册资本为人民币5.92亿元，其中宣城市国有资本投资运营控股集团有限公司出资4.79亿元，占比80.9122%，安徽省融资再担保有限公司出资1.13亿元, 比19.0878%。公司主营贷款担保、票据承兑担保、贸易融资担保、项目融资担保、信用证担保业务；兼营诉讼保全担保、履约担保业务，以自有资金进行投资业务。公司始终秉持“经营信用、管控风险、服务企业、促进发展”的经营宗旨，竭力为全市区域范围内的中小微企业提供方便、快捷、优质、高效的担保服务。随着担保能力的逐步提升，公司在缓解全市中小微企业融资难、促进地方经济社会发展中的作用日益增强；
// 2．担保项目：宣城市、区、县辖区交易中心进场项目；
// 3．保函有效期：一年，从投标保函开立（即保函开立申请人支付担保费）之日起算至次年的对应日，无对应日的提前一日。。
// 4．担保费：按照投标保函担保金额的0.5%收取担保费，低于200元的按200元收取。
// 5．担保最高限额：单个投标保函担保最高金额为50万元人民币，但为单个投标人提供的，仍在有效期内的投标保函的最高担保金额合计不超过1000万元。
// 6．保函性质：独立保函，即保函开立人出具的，同意受益人请求付款并提交保函要求的单据时，在保函最高金额内向其付款的承诺。
// 7．追偿：保函开立人按照保函记载的要求向受益人代偿后，将对保函申请人进行追偿。
// 8.保函项下的权利不得转让，不得设定担保。未经保函开立人书面同意转让本保函或其项下任何权利，对保函开立人不发生法律效力。
// 9.以上事项最终解释权归宣城市中小企业融资担保有限公司享有。


// 阅读保函开立须知


// 一、名称：投标保函。
// 二、保函形式：电子保函。根据《中华人民共和国民法典》第四百六十九条规定，电子保函与纸质保函具有同等法律效力。
// 三、请您在投保前务必详细阅读《投标保函申请书》，《投标保函开立、退费、代偿规则》等内容。
// 四、保函开立人根据相关费率标准综合确定，按照保函最高担保金额的0.5%收取担保费，低于200元的按200元收取。
// 五、请您在投保前务必详细阅读以下文件，并认真对待：
// （一）《电子保函服务平台操作手册》，附件下载。
// （二）《投标保函开立、退费、代偿规则》，附件下载。
// 六、投标保函由宣城市中小企业融资担保有限公司提供。
// 七、请您于项目《招标文件》规定的“投标保证金缴纳截止时间”前完成担保费支付，否则投保无效。
// 八、发票申请操作详见《电子保函服务平台操作手册》。
// 九、退保申请操作详见《投标保函开立、退费、代偿规则》和《电子保函服务平台操作手册》。
// 我已知晓通过本系统向保函开立人申请的投标保函过程中所有阅读、点击的内容均可作为法律证据，如保函开立人做出代偿可凭此内容作为法律证据向我司追偿本金及投标保函申请书中约定的其它费用。

export default tpl;
