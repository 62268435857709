<template>
  <div>
    <PlateTitle
      title="订单状态"
      :refresh="true"
    />
    <div class="order">
      <div class="grid-content bg-purple">
        <!-- <img
          src=""
          alt=""
        > -->
        <svg
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-daichuli" />
        </svg>
        <!-- <a
          v-if="data.status === '91' || data.status === '49'"
          :href="data.downloadUrl"
        >{{ statusStr }}</a>
        <a
          v-else-if="data.status === '29'"
          :href="data.preDownloadUrl"
        >{{ statusStr }}</a> -->
        <span>{{ statusStr }}</span>
        <p v-if="status === '1'">
          审核中
        </p>
      </div>
      <div class="grid-content bg-purple-light">
        <div>
          <span>订单编号</span>
          <span>{{ orderNo }}</span><br>
          <span>担保金额</span>
          <span>{{ data.sumAmount }}元</span>
        </div>
        <div>
          <span>下单时间</span>
          <span>{{ data.createDate }}</span><br>
          <span>担保费</span>
          <span>{{ data.sumPremium }}元</span>
        </div>
        <div>
          <div>
            <button
              class="custom-btn btn-4"
              @click="goBtn(isBtnStr)"
              v-if="isVisBtn"
            >
              <span>{{ isBtnStr }}</span>
            </button>
            <a-button
              type="primary"
              @click="back"
            >
              返回保函申请
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <!-- v-if="data.status === '91' || data.status === '49' || data.status === '29' || data.status === '31'" -->
    <a-button
      v-if="data.status === '91' || data.status === '99'"
      class="download"
      type="primary"
      @click="download"
    >
      下载保函
    </a-button>
    <PlateTitle title="投保信息" />
    <span
      v-if="(this.data.status === '11' || this.data.status === '31') && this.data.cleartext === '0'"
      class="test"
    >(项目待开标，信息加密展示)</span>
    <div class="main">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-item
          label="标段名称"
          has-feedback
        >
          <span>{{ data.sectionName }}</span>
        </a-form-item>
        <a-form-item
          label="项目标段编码"
          has-feedback
        >
          <span>{{ data.sectionCode }}</span>
        </a-form-item>
        <a-form-item
          label="受益人名称"
          has-feedback
        >
          <span>{{ data.tendereeName }}</span>
        </a-form-item>
        <a-form-item
          label="受益人统一社会信用代码"
          has-feedback
        >
          <span>{{ data.tendereeCreditCode }}</span>
        </a-form-item>
        <a-form-item
          label="投保企业名称"
          has-feedback
        >
          <span>{{ data.name }}</span>
        </a-form-item>
        <a-form-item
          label="投保企业统一社会信用代码"
          has-feedback
        >
          <span>{{ data.creditCode }}</span>
        </a-form-item>
        <a-form-item
          label="担保金额"
          has-feedback
        >
          <span>{{ data.sumAmount }}元</span>
        </a-form-item>
      </a-form>
    </div>
    <a-modal
      v-model="visible"
      :title="isBtnStr"
      @ok="submit"
      :mask-closable="false"
      ok-text="确认"
      cancel-text="取消"
    >
      <a-form
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-item
          label="手机号"
          v-if="isBtnStr !== '开票'"
        >
          <span>{{ data.mobile }}</span>
        </a-form-item>
        <div v-if="isBtnStr === '退保'">
          <a-form-item
            label="验证码"
            class="code"
            v-if="step === 0"
          >
            <a-input
              v-decorator="['code', {rules: [{required: true, message: '请输入验证码'},],}]"
              placeholder="请输入验证码"
              style="width: 275px;"
            />
            <img
              :src="codeImg"
              alt=""
              @click="code"
            >
          </a-form-item>
          <a-form-item
            label="短信验证码"
            class="code"
            v-if="step === 0"
          >
            <a-input
              v-decorator="['phoneCode', {rules: [{required: true, message: '请输入短信验证码'}],}]"
              placeholder="请输入短信验证码"
              style="width: 275px;"
            />
            <a
              v-if="isTime"
              @click="phoneCode"
            >获取验证码</a>
            <span v-else>{{ time }}s重新获取</span>
          </a-form-item>
          <a-form-item label="退保原因">
            <a-select
              v-decorator="['cancelReason', {rules: [{required: true, message: '请选择退保原因'}],}]"
              style="width: 275px;"
            >
              <a-select-option value="项目未开标，不想投标了">
                项目未开标，不想投标了
              </a-select-option>
              <a-select-option value="投标人失误，或选择投标项目错误">
                投标人失误，或选择投标项目错误
              </a-select-option>
              <a-select-option value="因项目变化等其他原因">
                因项目变化等其他原因
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <div v-if="isBtnStr === '开票'">
          <a-form-item label="发票抬头">
            <span>{{ data.name }}</span>
          </a-form-item>
          <a-form-item label="纳税人识别号">
            <span>{{ data.creditCode }}</span>
          </a-form-item>
          <a-form-item label="手机号">
            <span>{{ data.mobile }}</span>
          </a-form-item>
          <a-form-item label="发票类型">
            <a-select
              v-model="invoiceType"
              style="width: 275px;"
            >
              <!-- <a-select-option value="1">
                纸质（普票）
              </a-select-option> -->
              <a-select-option value="2">
                电子(普票)
              </a-select-option>
              <!-- <a-select-option value="3">
                电子(专票)
              </a-select-option> -->
            </a-select>
          </a-form-item>
          <a-form-item label="开户行">
            <a-input
              v-decorator="['bankInfo', {rules: [{required: true, message: '请输入开户行'}],}]"
              placeholder="请输入开户行"
              style="width: 275px;"
            />
          </a-form-item>
          <a-form-item label="开户账号">
            <a-input
              v-decorator="['bankAcc', {rules: [{required: true, message: '请输入开户账号'}],}]"
              placeholder="请输入开户账号"
              style="width: 275px;"
            />
          </a-form-item>
          <a-form-item label="联系电话">
            <a-input
              v-decorator="['phoneNo', {rules: [{required: true, message: '请输入联系电话'}],}]"
              placeholder="请输入联系电话"
              style="width: 275px;"
            />
          </a-form-item>
          <a-form-item label="邮箱">
            <a-input
              v-decorator="['email', {rules: [{required: true, message: '请输入邮箱'}],}]"
              placeholder="请输入邮箱"
              style="width: 275px;"
            />
          </a-form-item>
          <a-form-item label="注册地址">
            <a-input
              v-decorator="['addInfo', {rules: [{required: true, message: '请输入注册地址'}],}]"
              placeholder="请输入注册地址"
              style="width: 275px;"
            />
          </a-form-item>
          <a-form-item label="备注">
            <a-input
              v-decorator="['remark', {rules: [{}],}]"
              placeholder="请输入备注"
              style="width: 275px;"
            />
          </a-form-item>
          <a-alert
            message="开标后2-3个工作日开具"
            type="warning"
          />
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import PlateTitle from '@/components/PlateTitle';
import { getInformation } from '@/api/insure.js';
import { code, phoneCode, insureCancel, insureInvoice, certType } from '@/api/insure.js';
// import { dateFormat } from '@/utils/format.js';
// const querystring = require('querystring');
export default {
  name: 'Step2',
  components: {
    PlateTitle,
  },
  props: {
    step: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: {},
      orderNo: '',
      status: {
        1: '审核中',
        2: '用户取消',
        5: '审核失败',
        6: '审核失败',
        9: '审核成功',
        10: '审核成功',
        11: '待支付',
        13: '凭证审核中',
        14: '凭证审核失败',
        15: '已开标未支付',
        19: '支付成功',
        20: '三方审核未通过',
        21: '待生成预保函',
        25: '生成预保函失败',
        29: '生成预保函成功',
        31: '已购买成功，待开标',
        39: '已开标',
        41: '待生成保函',
        45: '生成保函失败',
        49: '生成保函成功',
        50: '三方退保审核中',
        51: '退保中',
        55: '退保失败',
        59: '退保成功',
        61: '退款中',
        65: '退款失败',
        69: '退款完成',
        70: '三方理赔审核中',
        71: '代偿中',
        75: '代偿失败',
        79: '代偿成功',
        91: '担保中',
        92: '投标已结束',
        99: '完成',
      },
      statusStr: '',
      isBtnStr: '',
      BtnTitle: '',
      isVisBtn: false,
      visible: false,
      form: this.$form.createForm(this, { name: 'form' }),
      uuid: '',
      codeImg: '',
      isTime: true,
      time: 60,
      // createDate: '',
      // 发票类型
      invoiceType: '2',
      type: '',
    };
  },
  mounted() {
    this.getInformation();
    this.code();
    // this.orderNo = querystring.parse(window.location.search)['?orderNo'];
    this.orderNo = this.getQueryString('orderNo');
    this.type = window.localStorage.getItem('type');
  },
  methods: {
    back() {
      this.$router.push({ path: '/orderCenter' });
    },
    getQueryString(name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
      const r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]); return null;
    },
    init() {
      certType().then(res => {
        console.log(res);
        this.certTypeList = res.data.data;
      });
    },
    handleChange() { },
    /* 图片验证码 */
    code() {
      code().then(res => {
        this.codeImg = res.img;
        this.uuid = res.uuid;
      });
    },
    /* 获取验证码 */
    phoneCode() {
      this.timer = '';
      console.log(this.form.instances);
      if (this.form.instances.code.stateValue !== '') {
        phoneCode({ codeKey: this.uuid, code: this.form.instances.code.stateValue, phoneNum: this.data.mobile }).then(res => {
          if (res === '') {
            this.isTime = false;
            this.timer = setInterval(this.countDown, 1000);
          }
        });
      } else {
        this.$message.warning('图形验证码不为空');
      }
    },
    countDown() {
      if (this.time > 0) {
        this.time--;
      } else {
        this.time = 60;
        this.isTime = true;
        clearInterval(this.timer);
      }
    },
    goBtn(type) {
      if (type === '支付') {
        window.open(this.data.payUrl);
      } else if (type === '退保') {
        this.visible = true;
      } else if (type === '开票') {
        // this.$refs.form.resetFields();
        this.visible = true;
        this.$nextTick(() => {
          this.form.setFieldsValue({
            bankInfo: this.data.bankInfo,
            bankAcc: this.data.bankAcc,
            email: this.data.email,
            addInfo: this.data.registerAdd,
            phoneNo: this.data.invoicePhone,
          });
        });

      } else if (type === '下载发票') {
        window.open(this.data.invoiceUrl);
      }
    },
    submit() {
      this.form.validateFields(err => {
        if (!err) {
          if (this.isBtnStr === '退保') {
            const params = {
              responsiblePersonPhone: this.data.mobile,
              orderNo: this.orderNo,
              phoneCode: this.form.fieldsStore.fields.phoneCode.value,
              cancelReason: this.form.fieldsStore.fields.cancelReason.value,
              // accountName: this.form.fieldsStore.fields.accountName.value,
              // bankName: this.form.fieldsStore.fields.bankName.value,
              // bankNo: this.form.fieldsStore.fields.bankNo.value,
            };
            insureCancel(params).then(res => {
              if (res.status === 200) {
                this.$notification.success({ message: res.message });
                this.getInformation();
                this.visible = false;
              } else {
                this.$notification.warning({ message: res.message });
              }
            }).catch(err => {
              this.$notification.error({ message: err.respomessage });
            });
          } else if (this.isBtnStr === '开票') {
            const params = {
              responsiblePersonPhone: this.data.mobile,
              orderNo: this.orderNo,
              invoiceType: this.invoiceType,
              invoiceHead: this.data.name,
              cuscCode: this.data.creditCode,
              bankInfo: this.form.fieldsStore.fields.bankInfo.value,
              bankAcc: this.form.fieldsStore.fields.bankAcc.value,
              phoneNo: this.form.fieldsStore.fields.phoneNo.value,
              addInfo: this.form.fieldsStore.fields.addInfo.value,
              email: this.form.fieldsStore.fields.email.value,
              remark: this.form.fieldsStore.fields.remark.value,
              invoiceStatus: '0',
            };
            insureInvoice(params).then(res => {
              if (res.data.status === 200) {
                this.$notification.success({ message: res.data.message });
                this.visible = false;
                this.getInformation();
              } else {
                this.$notification.warning({ message: res.data.message });
              }
            });
          }
        }
      });

    },
    download() {
      if (this.data.isInsureInvoice && this.data.invoiceBut) {
        this.goBtn('开票');
        // this.visible = true;
        // this.BtnTitle = '开票信息';
        return;
      }
      let url = '';
      if (this.data.status === '91' || this.data.status === '49') {
        url = this.data.signDownloadUrl;
      } else if (this.data.status === '29' || this.data.status === '31') {
        url = this.data.preSignDownloadUrl;
      }
      window.open(url, '_self');
    },
    getInformation() {
      this.orderNo = this.getQueryString('orderNo');
      getInformation({
        orderNo: this.orderNo,
      }).then(response => {
        const res = response.data;
        // this.data = res.data;
        this.data = res;
        this.statusStr = this.status[res.status];
        // this.createDate = dateFormat(new Date(res.createDate).getTime());
        this.isVisBtn = true;
        if (res.isInsureCancel && res.surrenderBut) {
          // this.isBtnStr = '退保';
        } else if ((res.status === '9' || res.status === '11' || res.status === '15' || res.status === '14') && res.payBut) {
          this.isBtnStr = '支付';
        } else if (res.isInsureInvoice && res.invoiceBut) {
          this.isBtnStr = '开票';
        } else {
          this.isVisBtn = false;
        }

        if (res.invoiceUrl && res.invoiceBut) {
          this.isBtnStr = '下载发票';
          this.isVisBtn = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.test {
  position: absolute;
  transform: translate(100px, -65px);
  color: #666;
}
.icon {
  width: 8em;
  height: 8em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.order {
  display: flex;
  align-items: center;
  margin: 20px 0 50px;
  .bg-purple {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      font-size: 20px;
    }
    a {
      font-size: 20px;
    }
    img {
      width: 100px;
      height: 100px;
    }
  }
  .bg-purple-light {
    display: flex;
    flex: 3;
    div {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      div {
        display: flex;
        justify-content: center;
        // transform: translateY(100px);
      }
    }
  }

  .custom-btn {
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
      7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
    outline: none;
    margin-bottom: 20px;
  }
  /* 4 */
  .btn-4 {
    // background-color: #4dccc6;
    // background-image: linear-gradient(315deg, #4dccc6 0%, #96e4df 74%);
    background: rgb(0, 172, 238);
    background: linear-gradient(
      0deg,
      rgba(0, 172, 238, 1) 0%,
      rgba(2, 126, 251, 1) 100%
    );
    line-height: 42px;
    padding: 0;
    border: none;
  }

  .btn-4:hover {
    // background-color: #89d8d3;
    // background-image: linear-gradient(315deg, #89d8d3 0%, #03c8a8 74%);
    background-color: rgb(0, 172, 238);
    background-image: linear-gradient(
      315deg,
      rgb(0, 172, 238) 0%,
      rgba(2, 126, 251, 1) 74%
    );
  }

  .btn-4:before,
  .btn-4:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.9),
      -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
      inset -4px -4px 6px 0 rgba(255, 255, 255, 0.9),
      inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
    transition: all 0.3s ease;
  }

  .btn-4:before {
    height: 0%;
    width: 0.1px;
  }

  .btn-4:after {
    width: 0%;
    height: 0.1px;
  }

  .btn-4:hover:before {
    height: 100%;
  }

  .btn-4:hover:after {
    width: 100%;
  }
}
/deep/ .ant-row {
  display: flex;
  justify-content: center;
}
/deep/ .ant-form-item {
  margin-bottom: 10px !important;
}
/deep/ .ant-form-item-control {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /*加宽度width属来兼容部分浏览*/
}
.code {
  img {
    position: absolute;
    cursor: pointer;
    left: 180px;
    top: -5px;
    height: 30px;
  }
  a,
  span {
    position: absolute;
    width: 80px;
    left: 192px;
  }
}
.download {
  position: absolute;
  transform: translateX(900px);
}
</style>
